isMobile = false;
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
}


if (document.querySelectorAll("#btn-go-top").length) {


    let mybutton = document.querySelector("#btn-go-top");
    window.onscroll = function () {
        scrollFunction()
    };

    function scrollFunction() {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    {
        document.querySelector("#btn-go-top").addEventListener("click", (e) => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: "smooth",
                passive: true,
            });
        });
    }
}

function checkMobile() {
    if (isMobile) {
        document.querySelector("body").classList.add("is--mobile");
        document.querySelector("body").classList.remove("is--desktop");
    } else {
        document.querySelector("body").classList.remove("is--mobile");
        document.querySelector("body").classList.add("is--desktop");
    }
}

checkMobile();

function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 && rect.left >= 0 &&
        // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

let swiperScript = 0;


window.slideUp = (target, duration = 500) => {

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

/* SLIDE DOWN */
window.slideDown = (target, duration = 500) => {

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

/* TOOGLE */
var slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}


const searchBox = document.getElementById("searchBox");
const searchBoxClose = document.getElementById("searchBoxClose");
const searchBoxOpen = document.getElementById("searchBoxOpen");

function closeSearchBox() {
    if (searchBox.classList.contains("open")) {
        searchBox.classList.remove("open");
    }
}

function openSearchBox() {
    if (!searchBox.classList.contains("open")) {
        searchBox.classList.add("open");
        searchBox.querySelector("input").focus();

    }
}

searchBoxClose.addEventListener("click", function (e) {
    e.preventDefault();
    closeSearchBox();
})
searchBoxOpen.addEventListener("click", function (e) {
    e.preventDefault();
    openSearchBox();
})

window.addEventListener('click', function (e) {
    if (document.getElementById('searchBoxOpen').contains(e.target) || document.getElementById('searchBox').contains(e.target)) {
        // Clicked in box
    } else {
        closeSearchBox();
    }
});


if (document.querySelectorAll(".header").length) {
    var navbar = document.querySelectorAll(".header")[0];

    function addFixedMenu() {
        if (window.pageYOffset >= 50) {
            navbar.classList.add("fixed-header-scroll");
            document.querySelector("body").classList.add("menu-is-fixed");
        } else {
            navbar.classList.remove("fixed-header-scroll");
            document.querySelector("body").classList.remove("menu-is-fixed");
        }
    }

    if (document.body.clientHeight > (window.innerHeight + 400)) {
        addFixedMenu();
        window.addEventListener("scroll", function (e) {
            addFixedMenu();
            closeSearchBox();
        });
    }
}


const navItemToOpenA = document.querySelectorAll('.nav__level-1>.menu__item--has-children>a');
const navItemToOpenLi = document.querySelectorAll('.nav__level-1>.menu__item--has-children');

const navItemToOpenA2 = document.querySelectorAll('.nav__level-2>li>a');
const navItemToOpenLi2 = document.querySelectorAll('.nav__level-2>li');

const navItemToOpenA3 = document.querySelectorAll('.nav__level-3>li>a');
const navItemToOpenLi3 = document.querySelectorAll('.nav__level-3>li');


document.querySelector("#nav-toggle").addEventListener("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    // let box = document.querySelector(".settings__wrapper");
    // if (box.classList.contains("open")) {
    //     box.classList.remove("open");
    // }

    this.classList.toggle("open");
    if (this.classList.contains("open")) {

        document.querySelector("body").classList.add("menu-open");
        document.querySelector(".nav").classList.add("open");
        slideDown(document.querySelector(".nav"), 300);


    } else {

        if (document.querySelector(".nav ul.open")) {
            document.querySelector(".nav").classList.remove("open");
        }
        document.querySelector(".nav").classList.remove("open");
        document.querySelector("body").classList.remove("menu-open");
        slideUp(document.querySelector(".nav"), 300);
    }
    e.preventDefault();
    e.stopPropagation();


})


var menuItems = document.querySelectorAll('li.menu__item--has-children.menu1>a');

Array.prototype.forEach.call(menuItems, function (el, i) {
    let parent = el.closest(".menu__item--has-children");
    let last = el.closest(".menu__item--has-children").querySelector("li:last-child>a");
    el.addEventListener("focus", function (event) {
        parent.className = "menu__item--has-children open";
    });
    last.addEventListener("focusout", function (event) {
        document.querySelector(".menu__item--has-children.open").className = "menu__item--has-children";

    });
});


if (window.ontouchstart !== undefined) {
    document.querySelector("body").classList.add("menu-mobile");

    for (var i = 0; i < navItemToOpenA.length; i++) {
        navItemToOpenA[i].addEventListener("click", function (e) {
            e.preventDefault();
        });
        navItemToOpenLi[i].addEventListener("click", function (e) {
            // e.preventDefault();
            // e.stopPropagation();
            let submenu = this.querySelector('.nav__level-2');

            if (submenu.classList.contains("m-open")) {
                slideUp(submenu, 300);
                submenu.classList.remove("m-open");
            } else {
                if (document.querySelector(".nav ul.m-open")) {
                    slideUp(document.querySelector(".nav ul.m-open"), 300);
                    document.querySelector(".nav ul.m-open").classList.remove("m-open");
                }
                slideDown(submenu, 300);
                submenu.classList.add("m-open");
            }
            if (document.querySelector(".nav .nav__level-3.m2-open")) {
                slideUp(document.querySelector(".nav .nav__level-3.m2-open"), 300);
                document.querySelector(".nav .nav__level-3.m2-open").classList.remove("m2-open");
            }

        });
    }
    for (var i = 0; i < navItemToOpenA2.length; i++) {

        navItemToOpenLi2[i].addEventListener("click", function (e) {
            //e.stopPropagation();
            //e.preventDefault();

            let submenu = this.querySelector(".nav__level-3");

            if (submenu.classList.contains("m2-open")) {
                slideUp(submenu, 300);
                submenu.classList.remove("m2-open");
            } else {
                if (document.querySelector(".nav .nav__level-3.m2-open")) {

                    slideUp(document.querySelector(".nav .nav__level-3.m2-open"), 300);
                    document.querySelector(".nav .nav__level-3.m2-open").classList.remove("m2-open");
                }
                slideDown(submenu, 300);
                submenu.classList.add("m2-open");
            }
        });
    }


} else {
    for (var i = 0; i < navItemToOpenA.length; i++) {
        navItemToOpenA[i].addEventListener("click", function (e) {
            // e.preventDefault();
            // e.stopPropagation();
        });
        navItemToOpenA2[i].addEventListener("click", function (e) {
            // e.preventDefault();
            // e.stopPropagation();
        });
    }
    for (var i = 0; i < navItemToOpenLi.length; i++) {
        navItemToOpenLi[i].addEventListener("mouseenter", function (e) {
            document.body.classList.add("menu-d-open");
            this.querySelector('.nav__level-2').style.display = "block";


            if (document.querySelector('.open-l')) {
                document.querySelector('.open-l').classList.remove("open-l");
            }

            if (this.querySelector('.nav__level-2>li:first-child')) {
                this.querySelector('.nav__level-2>li:first-child').classList.add('open-l');
            }
            if (this.querySelector('.nav__level-2>li:first-child>.nav__level-3')) {
                this.querySelector('.nav__level-2>li:first-child>.nav__level-3').classList.add('open');
            }

        });
    }
    for (var i = 0; i < navItemToOpenLi.length; i++) {
        navItemToOpenLi[i].addEventListener("mouseleave", function (e) {
            this.querySelector('.nav__level-2').style.display = "none";
            this.classList.remove('active');

            document.body.classList.remove("menu-d-open");
        });
    }
    for (var i = 0; i < navItemToOpenLi2.length; i++) {
        navItemToOpenLi2[i].addEventListener("mouseenter", function (e) {
            if (document.querySelector('.nav__level-2')) {
                if (this.querySelector('.nav__level-3')) {
                    document.querySelector('.nav__level-3').classList.remove("open");
                }
            }
            if (document.querySelector('.nav__level-2 a.active')) {
                document.querySelector('.nav__level-2 a.active').classList.remove("active");
            }
            if (document.querySelector('.open-l')) {
                document.querySelector('.open-l').classList.remove("open-l");
            }
            if (this.querySelector('.nav__level-3')) {
                this.querySelector('.nav__level-3').classList.add("open");

                this.classList.add("open-l");
            }

        });
    }
    for (var i = 0; i < navItemToOpenLi2.length; i++) {
        navItemToOpenLi2[i].addEventListener("mouseleave", function (e) {
            if (this.querySelector('.nav__level-3')) {
                this.querySelector('.nav__level-3').classList.remove("open");
            }
            this.classList.add('remove');
        });
    }
    for (var i = 0; i < navItemToOpenLi3.length; i++) {
        navItemToOpenLi3[i].addEventListener("mouseenter", function (e) {

            if (document.querySelector('.nav__level-4')) {
                document.querySelector('.nav__level-4').classList.remove("open");
            }
            if (document.querySelector('.nav__level-3 a.active')) {
                document.querySelector('.nav__level-3 a.active').classList.remove("active");
            }
            if (this.querySelector('.nav__level-4')) {
                this.querySelector('.nav__level-4').classList.add("open");
            }

        });
    }
    for (var i = 0; i < navItemToOpenLi3.length; i++) {
        navItemToOpenLi3[i].addEventListener("mouseleave", function (e) {
            if (this.querySelector('.nav__level-4')) {
                this.querySelector('.nav__level-4').classList.remove("open");
            }
            this.classList.add('remove');
        });
    }


}

function changeShowHide(btn) {
    let accordionHide = btn.querySelector(".hide");
    let accordionShow = btn.querySelector(".show");

    accordionHide.classList.remove("hide");
    accordionHide.classList.add("show");


    accordionShow.classList.remove("show");
    accordionShow.classList.add("hide");
}

function accordion(accordion) {

    let accordionContent = accordion.querySelector(".accordion__content");
    let accordionBtn = accordion.querySelector(".accordion__trigger");

    accordionBtn.addEventListener("click", function (e) {

        e.preventDefault();
        if (accordionBtn.getAttribute("aria-expanded") == "true") {
            accordionBtn.setAttribute('aria-expanded', false);
            accordionContent.setAttribute('aria-hidden', true);
            if (accordion.classList.contains("active")) {
                accordion.classList.remove("active");
            }
            slideUp(accordionContent);

        } else {
            accordionBtn.setAttribute('aria-expanded', true);
            accordionContent.setAttribute('aria-hidden', false);
            accordion.classList.add("active");
            slideDown(accordionContent);
        }


        if (accordion.closest(".swiper")) {
            if (accordion.closest(".swiper").querySelector(".accordion.active")) {

                (accordion.closest(".swiper")).classList.add("swiper-accordion-active")
            } else {
                if (accordion.closest(".swiper").classList.contains("swiper-accordion-active")) {
                    accordion.closest(".swiper").classList.remove("swiper-accordion-active")
                }
            }
        }
        if (accordionBtn.querySelector(".hide")) {
            changeShowHide(accordionBtn);
        }
    })
}

Array.prototype.map.call(document.querySelectorAll(".accordion"), function (item) {
    accordion(item)
});


if (document.getElementById("scrollDown")) {
    document.getElementById("scrollDown").addEventListener("click", function (e) {
        e.preventDefault();
        window.scroll({
            top: 400,
            left: 0,
            behavior: 'smooth'
        });
    })
}


if (document.querySelector(".c-scroll-down")) {
    const btn = document.querySelectorAll(".c-scroll-down");

    for (let i = 0; i < btn.length; i++) {
        btn[i].addEventListener("click", function (e) {
            e.preventDefault();


            let topOffset = window.scrollY + 200;

            window.scroll({
                top: topOffset,
                left: 0,
                behavior: 'smooth'
            });
        })
    }
}


//TAB
function tabsRun(wrapper) {
    var tabs = wrapper.querySelectorAll('[role=tab]'); //get all role=tab elements as a variable
    for (i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", showTabPanel);
    } //add click event to each tab to run the showTabPanel function 
    function showTabPanel(el) { //runs when tab is clicked
        var tabs2 = wrapper.querySelectorAll('[role=tab]'); //get tabs again as a different variable 
        for (i = 0; i < tabs2.length; i++) {
            tabs2[i].setAttribute('aria-selected', 'false');

        } //reset all tabs to aria-selected=false and normal font weight
        el.target.setAttribute('aria-selected', 'true'); //set aria-selected=true for clicked tab       
        var tabPanelToOpen = el.target.getAttribute('aria-controls');
        if (!tabPanelToOpen) {

            tabPanelToOpen = el.target.close("button").getAttribute('aria-controls');
        } //get the aria-controls value of the tab that was clicked
        var tabPanels = wrapper.querySelectorAll('[role=tabpanel]'); //get all tabpanels as a variable
        for (i = 0; i < tabPanels.length; i++) {
            tabPanels[i].setAttribute("aria-hidden", "true");
            tabPanels[i].classList.remove("is-current");
        } //hide all tabpanels

        wrapper.querySelector("#" + tabPanelToOpen).setAttribute("aria-hidden", "false");
        wrapper.querySelector("#" + tabPanelToOpen).classList.add("is-current"); //show tabpanel who's tab was clicked
    }
}

Array.prototype.map.call(document.querySelectorAll(".tabs"), function (item) {
    tabsRun(item)
});

function tabsRun2(wrapper) {
    var tabs = wrapper.querySelectorAll('.tabs2__nav-trigger'); //get all role=tab elements as a variable
    for (i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", showTabPanel);
    } //add click event to each tab to run the showTabPanel function
    function showTabPanel(el) { //runs when tab is clicked
        var tabs2 = wrapper.querySelectorAll('.tabs2__nav-trigger'); //get tabs again as a different variable
        for (i = 0; i < tabs2.length; i++) {
            tabs2[i].setAttribute('aria-selected', 'false');

        } //reset all tabs to aria-selected=false and normal font weight
        el.target.setAttribute('aria-selected', 'true'); //set aria-selected=true for clicked tab
        var tabPanelToOpen = el.target.getAttribute('aria-controls');
        if (!tabPanelToOpen) {

            tabPanelToOpen = el.target.close("button").getAttribute('aria-controls');
        } //get the aria-controls value of the tab that was clicked
        var tabPanels = wrapper.querySelectorAll('.tabs2__panel'); //get all tabpanels as a variable
        for (i = 0; i < tabPanels.length; i++) {
            tabPanels[i].setAttribute("aria-hidden", "true");
            tabPanels[i].classList.remove("is-current");
        } //hide all tabpanels

        wrapper.querySelector("#" + tabPanelToOpen).setAttribute("aria-hidden", "false");
        wrapper.querySelector("#" + tabPanelToOpen).classList.add("is-current"); //show tabpanel who's tab was clicked
    }
}

Array.prototype.map.call(document.querySelectorAll(".tabs2"), function (item) {
    tabsRun2(item)
});


let touchstartX = 0;
let touchendX = 0;

if (document.querySelector('.elements__right')) {
    const gestureZone = document.querySelector('.elements__right');


    gestureZone.addEventListener('touchstart', function (event) {
        touchstartX = event.changedTouches[0].screenX;
    }, false);

    gestureZone.addEventListener('touchend', function (event) {
        touchendX = event.changedTouches[0].screenX;
        handleGesture();
    }, false);
}

function handleGesture() {
    if (touchendX >= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling) {
            document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling.querySelector("button").click();
        }
    }
    if (touchendX <= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling) {
            document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling.querySelector("button").click()
        }
    }

};


(function () {
    /* Opening modal window function */
    function openModal() {
        /* Get trigger element */
        var modalTrigger = document.getElementsByClassName('jsModalTrigger');

        /* Set onclick event handler for all trigger elements */
        for (var i = 0; i < modalTrigger.length; i++) {

            modalTrigger[i].onclick = function (e) {
                e.preventDefault();
                var target = this.getAttribute('data-href');
                var modalWindow = document.getElementById(target);

                modalWindow.classList ? modalWindow.classList.add('open') : modalWindow.className += ' ' + 'open';
            }
        }
    }

    function closeModal() {
        /* Get close button */
        var closeButton = document.getElementsByClassName('jsModalClose');
        var closeOverlay = document.getElementsByClassName('jsOverlay');

        /* Set onclick event handler for close buttons */
        for (var i = 0; i < closeButton.length; i++) {
            closeButton[i].onclick = function () {
                var modalWindow = this.parentNode.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

        /* Set onclick event handler for modal overlay */
        for (var i = 0; i < closeOverlay.length; i++) {
            closeOverlay[i].onclick = function () {
                var modalWindow = this.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

    }

    /* Handling domready event IE9+ */
    function ready(fn) {
        if (document.readyState != 'loading') {
            fn();
        } else {
            document.addEventListener('DOMContentLoaded', fn);
        }
    }

    /* Triggering modal window function after dom ready */
    ready(openModal);
    ready(closeModal);


}());


if (document.getElementById("hero__move")) {

    let heroLeft = (document.querySelector(".hero-clipping-wrapper").getBoundingClientRect()).left;
    let heroRight = document.querySelector(".hero__right").clientWidth;


    if (window.innerWidth > 576) {
        document.querySelector(".hero-clipping-wrapper").style.width = "50%";
    } else {
        document.querySelector(".hero-clipping-wrapper").style.width = "100px";
    }

    let isDown = false;
    let offsetLeft = 0;

    document.querySelector("#hero__move").addEventListener("mousedown", function (event) {
        isDown = true;
        offsetLeft = event.pageX - heroLeft;

    }, true);
    document.addEventListener('mouseup', function () {
        isDown = false;
    }, true);
    document.addEventListener('mousemove', function (event) {
        event.preventDefault();
        if (isDown) {
            offsetLeft = event.pageX - heroLeft;
            if (offsetLeft > 0 && offsetLeft < heroRight) {
                document.querySelector(".hero-clipping-wrapper").style.width = offsetLeft + "px";
            }
        }
    }, true);

    document.querySelector(".hero__right").addEventListener("touchmove", function (event) {
        offsetLeft = event.touches[0].clientX;
        document.querySelector(".hero-clipping-wrapper").style.width = offsetLeft + "px";

    });


    // document.querySelector("#hero__move").addEventListener("touchmove", function(event) {
    //     pointerX = event.touches[0].clientX - heroLeft;;
    //     pointerY = event.pageY;
    //     if (pointerX > 0) { document.querySelector(".hero-clipping-wrapper").style.width = pointerX + "px"; }

    // });


}


//ulubione

var http_requestX = false;

/*
data - dane do wysłania
callbackF - funkcja zwrotna
itemEl - elemnt na kórym wywołujemy funkcje zwrotną /opcjonalny
*/
window.makeRequest = function (url = '/a/', method = 'POST', data, callbackF, itemEl) {

    http_requestX = false;

    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_requestX = new XMLHttpRequest();
        if (http_requestX.overrideMimeType) {
            http_requestX.overrideMimeType('text/xml');
            // Przeczytaj o tym wierszu poniżej
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_requestX = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_requestX = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
            }
        }
    }

    if (!http_requestX) {
        alert('Nie mogę stworzyć instancji obiektu XMLHTTP');
        return false;
    }

    http_requestX.onreadystatechange = function () {
        alertContents2(http_requestX, callbackF, itemEl)
    };
    http_requestX.open(method, url, true);
    http_requestX.send(data);
}

function alertContents2(http_requestX, functionName, itemEl) {
    try {
        if (http_requestX.readyState == 4) {
            if (http_requestX.status == 200) {


                functionName(http_requestX.responseText, itemEl);


            } else {

                alert('Wystąpił problem z tym żądaniem.');
            }
        }
    } catch (e) {

    }
}


function slideTabNav(tab) {
    if (tab.querySelector(".pr__nav-slider")) {
        let pageNavBtns = tab.querySelectorAll(".tabs__nav-trigger");
        const slider = tab.querySelector(".pr__nav-slider");

        slider.style.width = (pageNavBtns[0].clientWidth) + "px";
        for (let i = 0; i < pageNavBtns.length; ++i) {

            pageNavBtns[i].addEventListener('click', function (e) {
                e.preventDefault();
                let bodyPadding = tab.querySelector(".pr__nav--count").getBoundingClientRect().left;
                let margin = pageNavBtns[i].getBoundingClientRect().left - bodyPadding;
                slider.style.width = (pageNavBtns[i].clientWidth) + "px";
                slider.style.marginLeft = margin + "px";
            })
        }
    }
}

Array.prototype.map.call(document.querySelectorAll(".tabs"), function (tab) {
    slideTabNav(tab)
});


// function slideLinkNav(tab) {
//     let pageNavLink = tab.querySelector(".tabs__nav-item.is-active a");
//     const slider = tab.querySelector(".pr__nav-slider");
//     slider.style.width = (pageNavLink.clientWidth) + "px";
//     let bodyPadding = tab.querySelector(".pr__nav").getBoundingClientRect().left;
//     let margin = pageNavLink.getBoundingClientRect().left - bodyPadding;
//     slider.style.width = (pageNavBtns[i].clientWidth) + "px";
//     slider.style.marginLeft = margin + "px";
// }
// Array.prototype.map.call(document.querySelectorAll(".tabs--links"), function (tab) {
//     slideLinkNav(tab)
// });




if (document.querySelector(".nav2-list")) {

    let observeOptionsFast2 = {
        root: null,
        rootMargin: '-50% 0% -50% 0%',
        threshold: 0,
    }
    let bodyPadding = document.querySelector(".nav2").getBoundingClientRect().left;
    const slider = document.querySelector(".nav2-slider");
    let navActive;
    if (document.querySelector(".nav2-slider .active")) {
        navActive = document.querySelector(".nav2-list .active");
    } else {
        navActive = document.querySelector(".nav2-list li:first-child a");
    }

    const navActiveMargin = navActive.getBoundingClientRect().left - bodyPadding + 10;


    slider.style.marginLeft = navActiveMargin + "px";
    setTimeout(function () {
        slider.style.width = (navActive.clientWidth - 20) + "px";
        slider.classList.add("is-start");
    }, 300)

    let onObservePageBtn = function (entries, observer) {
        for (let i = 0; i < entries.length; ++i) {
            let el = entries[i].target;

            if (entries[i].isIntersecting) {
                let id = el.getAttribute("id");

                if (document.querySelector("[data-href=" + id + "]")) {
                    let navEl = document.querySelector("[data-href=" + id + "]");


                    let margin = navEl.getBoundingClientRect().left - bodyPadding + 10;

                    slider.style.width = (navEl.clientWidth - 20) + "px";
                    slider.style.marginLeft = margin + "px";
                    document.querySelector(".nav2-list .active").classList.remove("active");
                    navEl.classList.add("active");
                }
            }
        }
    }

    let pageNavBtns = document.querySelectorAll(".nav2-link");
    let navListMobile = document.querySelector(".nav2-list");


    for (let i = 0; i < pageNavBtns.length; ++i) {
        pageNavBtns[i].addEventListener('click', function (e) {
            e.preventDefault();
            let href = "#" + e.target.getAttribute('data-href');
            let elem = document.querySelector(href);
            let oT = elem.offsetTop - 70;

            window.scroll({
                top: oT,
                behavior: 'smooth',
                passive: true,
            });

        })
    }
    let articleItem = document.querySelectorAll(".article2");
    for (let i = 0; i < articleItem.length; ++i) {
        const iObserverNavBtns = new IntersectionObserver(onObservePageBtn, observeOptionsFast2);
        iObserverNavBtns.observe(articleItem[i]);
    }


}






